@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

.App {
  padding-top: 30px;
  padding-bottom: 20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

h1 {
  padding-top: 90px;
  padding-bottom: 90px;
  font-weight: 500;
  text-align: center;
  color: rgb(94, 93, 93);
}

h2 {
  color: #333;
  font-size: 2rem;
  font-weight: 500;
}

h3 {
  font-weight: 500;
  font-size: 1.5rem;
  color: #666;
}

.main {
  margin-top: 80px;
  margin-bottom: 10px;
}

@media (hover: none) {
  .display {
    display: flex;
    margin: 20px -8px;
    align-items: center;
  }

  .display-outer:nth-child(even) .display {
    flex-direction: row-reverse;
  }

  .shadow1 {
    box-shadow: 10px 10px 20px rgba(94, 93, 93, 0.6);
  }

  .shadow2 {
    box-shadow: -10px 10px 20px rgba(94, 93, 93, 0.6);
  }

  .pic {
    width: 65vw;
    height: 40vw;
  }
}

@media (max-width: 830px) {
  .display {
    flex-wrap: wrap;
  }

  .pic {
    width: 90vw;
    height: 50vw;
  }
}

@media (hover: hover) {
  .main {
    display: flex;
    width: fit-content;
    box-shadow: 0px 5px 10px rgba(94, 93, 93, 0.6);
  }

  /* ( 1750px = 5 * card's-min-width )*/
  @media (max-width: 1750px) {
    .main {
      animation: 12s slider infinite;
    }

    @keyframes slider {
      100% {
        transform: translateX(calc(100vw - 1750px));
      }
    }

    .main:hover {
      animation-play-state: paused;
    }
  }

  .slider {
    margin: 0 -8px;
    overflow: hidden;
  }

  .display-outer {
    height: 260px;
    width: 20vw;
    min-width: 350px; /* card's-min-width */
    perspective: 1000px;
  }

  .display {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.7s;
    transform-style: preserve-3d;
  }

  .display-outer:hover .display {
    transform: rotateY(180deg);
  }

  .pic {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #565656;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }
}

.box {
  background-color: #f5b21a;
  font-size: 0.9rem;
  text-align: center;
  color: white;
  margin: 0 -8px;
  padding-top: 70px;
  padding-bottom: 60px;
  margin-bottom: 70px;
}

.booking {
  text-align: center;
  padding: 0 10vw;
  margin: auto;
  max-width: 1200px;
}

.button {
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 60px;
  font-size: 14px;
  width: 100%;
  color: white;
  background-color: #565656;
  padding: 14px 20px;
  border-radius: 5px;
  border: 0;
}
