.consultation {
  display: flex;
  justify-content: space-between;
  padding: 5vw;
  color: #8a9399;
}

.consultation h2 {
  color: #666;
  margin-left: 2vw;
  margin-right: 2vw;
}

@media (max-width: 850px) {
  .consultation {
    padding-bottom: 10vw;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .consultation h2 {
    margin-left: 0;
  }
}

@media (min-width: 1550px) {
  .consultation h2 {
    margin-left: 5vw;
    margin-right: 5vw;
  }
}

@media (min-width: 2000px) {
  .consultation h2 {
    margin-left: 15vw;
    margin-right: 15vw;
  }
}

.form {
  display: flex;
  flex: 2;
  min-width: 360px;
  max-width: 800px;
  flex-direction: column;
}

.form input,
textarea {
  width: 80%;
  padding: 10px;
  margin: 10px 0;
  font-family: inherit;
  font-size: inherit;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

.form button {
  width: 84%;
  padding: 10px;
  font-size: inherit;
  border: 0px;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  background-color: #2fa4e7;
}

.form textarea {
  resize: vertical;
  height: 140px;
}
