.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 5vw;
  padding-right: 7vw;
}

.copyright {
  color: grey;
  margin-top: 10px;
  margin-left: 5vw;
}

@media (max-width: 750px) {
  .footer {
    flex-wrap: wrap;
  }
}
